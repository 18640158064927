import React, { useState, useEffect } from "react";
import Moment from 'moment';
import { navigate } from "gatsby";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo, faUserFriends, faCalendarPlus, faTrashAlt, faRedo } from '@fortawesome/free-solid-svg-icons'
import Jitsi from 'react-jitsi';

import Layout from "../components/layout";
import { getScheduledMeetings, updateScheduleMeetings } from "../helpers/firebase";
import { getRecentMeetings, saveRecentMeeting, removeRecentMeeting } from "../helpers/local";
import { getUser } from "../helpers/auth";
import InviteModal from "../components/invite-modal";

const Dashboard = () => {
    const {displayName, uid} = getUser();
    const [selectedTab, setSelectedTab] = useState('schedule');
    const [scheduledMeetings, setScheduledMeetings] = useState([]);
    const [recentMeetings, setRecentMeetings] = useState([]);
    const [filteredScheduledMeetings, setFilteredScheduledMeetings] = useState([]);
    const [selectedMeeting, setSelectedMeeting] = useState(null);

    const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
    const [onCall, setOnCall] = useState(false);

    useEffect(() => {
        if (uid) getScdMeetings();
        
        setRecentMeetings(getRecentMeetings());

        return () => {}
    }, [selectedTab, onCall]);

    const getScdMeetings = async () => {
        const scdMeetings = await getScheduledMeetings(uid);
        const filteredScdMeetings = scdMeetings.filter((schMeeting) => {
            return schMeeting.startTime > Math.floor(new Date().getTime() / 1000)
        });
        setScheduledMeetings(scdMeetings);
        setFilteredScheduledMeetings(filteredScdMeetings);
    }

    const handleTab = (tab) => {
        setSelectedTab(tab);
    }

    const handleInvite = (index) => {
        setSelectedMeeting(filteredScheduledMeetings[index]);
        setIsInviteModalOpen(true);
    }

    const handleStart = (index) => {
        setSelectedMeeting(filteredScheduledMeetings[index]);
        setOnCall(true);
    }

    const handleCancel = (index) => {
        let filteredScdMeetings = [...filteredScheduledMeetings];
        filteredScdMeetings.splice(index, 1);

        let scdMeetings = [...scheduledMeetings];
        const index2 = scdMeetings.findIndex((scdMeeting) => {
            return scdMeeting.meetingId == filteredScheduledMeetings[index].meetingId
        });
        scdMeetings.splice(index2, 1);

        setFilteredScheduledMeetings(filteredScdMeetings);
        updateScheduleMeetings(uid, scdMeetings);
    }

    const handleDelete = (index) => {
        let rMeetings = [...recentMeetings];
        rMeetings.splice(index, 1);

        setRecentMeetings(rMeetings);
        removeRecentMeeting(index);
    }

    const handleRetry = (index) => {
        setSelectedMeeting(recentMeetings[index]);
        setOnCall(true);
    }

    const jitsiAPILoad = (JitsiMeetAPI) => {
        JitsiMeetAPI.addEventListener("videoConferenceLeft", () => {
            setOnCall(false);
        });
        JitsiMeetAPI.addEventListener("suspendDetected", () => {
            setOnCall(false);
        });
        saveRecentMeeting({
            meetingTitle: selectedMeeting.meetingTitle ? selectedMeeting.meetingTitle : selectedMeeting.meetingId,
            meetingPassword: selectedMeeting.meetingPassword,
            meetingId: selectedMeeting.meetingId,
            hostPreferences: {
                audio: selectedMeeting.hostPreferences.audio,
                video: selectedMeeting.hostPreferences.video,
            },
            joinedTime: Math.floor(new Date().getTime() / 1000)
        });
    }

    const renderScheduledMeetings = () => {
        return filteredScheduledMeetings.map((scheduledMeeting, index) => {
            return (
                <div key={index} className="ui_box">
                    <div className="s_details">
                        <h4>{scheduledMeeting.meetingTitle}</h4>
                        <p className="host_p">Host: {displayName}</p>
                        <p className="host_p">Date: {Moment.unix(scheduledMeeting.startTime).format('D MMM YYYY')} <span>Time: {Moment.unix(scheduledMeeting.startTime).format('h:mm a')}</span></p>
                    </div>
                    <div className="s_controls">
                        <div className="invite_plus" onClick={() => handleInvite(index)}>Invite +</div>
                        <div className="btn_start" onClick={() => handleStart(index)}>Start</div>
                        <div className="btn_cancel" onClick={() => handleCancel(index)}>Cancel</div>
                    </div>
                </div>
            )
        })
    }
    
    const renderHistoryMeetings = () => {
        return recentMeetings.sort((r1, r2) => {
            return r2.joinedTime - r1.joinedTime
        }).map((recentMeeting, index) => {
            return (
                <div key={index} className="ui_box">
                    <div className="h_details">
                        <h4>{recentMeeting.meetingTitle}</h4>
                        <p className="host_p">{Moment(Moment.unix(recentMeeting.joinedTime)).fromNow()}</p>
                    </div>
                    <div className="h_controls">
                        <div className="btn_delete" onClick={() => handleDelete(index)}>
                            <FontAwesomeIcon className="fa_icon" icon={faTrashAlt} />
                        </div>
                        <div className="btn_retry" onClick={() => handleRetry(index)}>
                            <FontAwesomeIcon className="fa_icon" icon={faRedo} />
                        </div>
                    </div>
                </div>
            )    
        })
    }

    return (
        <div>
            { 
                onCall && 
                <Jitsi
                    containerStyle={{ width: window.innerWidth, height: window.innerHeight }}
                    roomName={selectedMeeting ? selectedMeeting.meetingId : ''}
                    password={selectedMeeting ? selectedMeeting.meetingPassword : ''}
                    displayName={selectedMeeting ? selectedMeeting.meetingTitle : ''} 
                    onAPILoad={JitsiMeetAPI => jitsiAPILoad(JitsiMeetAPI)}
                    domain="app.meetnow.in" />
            }
            {
                !onCall && 
                <Layout>
                    <InviteModal 
                        open={isInviteModalOpen}
                        inviteMessageTitle = {selectedMeeting ? (selectedMeeting.inviteMessage ? selectedMeeting.inviteMessage.title : '') : ''}
                        inviteMessageBody = {selectedMeeting ? (selectedMeeting.inviteMessage ? selectedMeeting.inviteMessage.body : '') : ''}
                        inviteMessageFooter = {selectedMeeting ? (selectedMeeting.inviteMessage ? selectedMeeting.inviteMessage.footer : '') : ''}
                        meetingTitle = {selectedMeeting ? selectedMeeting.meetingTitle : ''} 
                        meetingId = {selectedMeeting ? selectedMeeting.meetingId : ''}
                        meetingPassword = {selectedMeeting ? selectedMeeting.meetingPassword : ''}
                        meetingTime = {selectedMeeting ? selectedMeeting.startTime : ''}
                        onClose={(type) => {
                            setIsInviteModalOpen(false);
                        }}
                    />
                    <div className="row pdtop">
                        <div className="col-md-4">
                            <div className="n_meeting_box n_meeting_box" onClick={() => navigate("/newMeeting")}>
                                <div className="btn_meeting">
                                    <FontAwesomeIcon className="fa_icon" icon={faVideo} />
                                    <h5>New <br/> meeting</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="n_meeting_box j_meeting_box" onClick={() => navigate("/joinMeeting")}>
                                <div className="btn_meeting">
                                    <FontAwesomeIcon className="fa_icon" icon={faUserFriends} />
                                    <h5>Join <br/> a meeting</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="n_meeting_box s_meeting_box" onClick={() => navigate("/scheduleMeeting")}>
                                <div className="btn_meeting">
                                    <FontAwesomeIcon className="fa_icon" icon={faCalendarPlus} />
                                    <h5>Schedule <br/> a meeting</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab_bars">
                        <div className={`schedule_tab_bar ${selectedTab == 'schedule' ? 'selected' : ''}`} onClick={() => handleTab('schedule')}>Schedule</div>
                        <div className={`history_tab_bar ${selectedTab == 'history' ? 'selected' : ''}`} onClick={() => handleTab('history')}>History</div>
                    </div>
                    <div className="tabs">
                        {
                            selectedTab == 'schedule' ? 
                                <div className="tab_box schedule_tab">
                                    {renderScheduledMeetings()}
                                </div>
                            :
                                <div className="tab_box history_tab">
                                    {renderHistoryMeetings()}
                                </div>
                        }
                    </div>
                </Layout>
            }
        </div>
    )
}
export default Dashboard